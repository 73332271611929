// Define las fechas clave
const startPreventa = new Date('2024-07-30').getTime();
const endPreventa = new Date('2024-08-16').getTime();
const startFase1 = new Date('2024-08-16').getTime();
const endFase1 = new Date('2024-09-16').getTime();
const startFase2 = new Date('2024-09-16').getTime();
const endFase2 = new Date('2024-10-05').getTime();
const startTaquilla = new Date('2024-10-05').getTime();
const endFaseTaquilla = new Date('2024-10-06').getTime();
const today = new Date().getTime();

const Boletos = () => {
  return (
    <section id="tickets" className="sectionTickets" name="boletos">
      <div className="d-block bgTickets"></div>
      <div className="container">
        <div className="row">
          <h1 className="titleTickets text-capitalize text-center mb-2 mb-lg-5">COMPRA TUS BOLETOS</h1>
        </div>
      </div>
      <div className="container-fluid px-0">
        <div className="containerTickets row pt-5">
          {/* Preventa */}
          {startPreventa <= today && today <= endPreventa ? (
            <div className="col-6 col-lg-3 ticketImg  h-100 text-center">
              <a className="linkPrice" href="https://nas.io/checkout-global?communityId=668edff26c0d063983ef6674&communityCode=BBMUNDO&requestor=signupRequestor&sourceInfoType=event&sourceInfoOrigin=66a819c86e2ac8c293488e7b">
                <img
                  className="ticketImg position-relative"
                  loading="lazy"
                  src="https://masterbbmundo.s3.amazonaws.com/Boletos/BackBoleto_1.webp"
                  alt="logoBbmundo"
                  width="500"
                  height="auto"
                />
                <div className="sectionsTicket">
                  <p className="text-center priceTickets mb-3 title">PREVENTA</p>
                  <p className="text-center priceTickets text-light mb-2 mb-lg-5 pb-2 pb-lg-5">1 ago - 15 ago</p>
                  <img
                    className="ticket position-relative py-2 py-lg-2"
                    loading="lazy"
                    src="https://masterbbmundo.s3.amazonaws.com/Boletos/Boleto_1.webp"
                    alt="ticket"
                    width="500"
                    height="auto"
                  />
                  <div className="priceTicket pt-3">
                    <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center mt-0">
                      Individual: <span>$900</span>
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ) : (
            <div className="col-6 col-lg-3 ticketImg  disableTicket h-100 text-center">
              <img
                className="ticketImg position-relative"
                loading="lazy"
                src="https://masterbbmundo.s3.amazonaws.com/Boletos/BackBoleto_1.webp"
                alt="logoBbmundo"
                width="500"
                height="auto"
              />
              <div className="sectionsTicket">
                <p className="text-center priceTickets mb-3 disableText title">PREVENTA</p>
                <p className="text-center priceTickets disableText text-light pb-2 pb-lg-5">1 ago - 15 ago</p>
                <img
                  className="ticket position-relative disableImg py-2 py-lg-5"
                  loading="lazy"
                  src="https://masterbbmundo.s3.amazonaws.com/Boletos/Boleto_1.webp"
                  alt="ticket"
                  width="500"
                  height="auto"
                />
                <div className="priceTicket pt-3">
                  <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center disableText">
                    Individual: <span>$900</span>
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Fase 1 */}
          {startFase1 <= today && today <= endFase1 ? (
            <div className="col-6 col-lg-3 ticketImg  h-100 text-center">
              <img
                className="ticketImg position-relative"
                loading="lazy"
                src="https://masterbbmundo.s3.amazonaws.com/Boletos/BackBoleto_2.webp"
                alt="logoBbmundo"
                width="500"
                height="auto"
              />
              <div className="sectionsTicket">
                <p className="text-center priceTickets mb-3 title">FASE 1</p>
                <p className="text-center priceTickets text-light mb-2 mb-lg-5">16 de ago al 15 de sep</p>
                <img
                  className="ticket position-relative"
                  loading="lazy"
                  src="https://masterbbmundo.s3.amazonaws.com/Boletos/Boleto_2.webp"
                  alt="ticket"
                  width="500"
                  height="auto"
                />
                <div className="priceTicket">
                  <a className="linkPrice" rel="noreferrer" target="_blank" href="https://nas.io/checkout-global?communityId=668edff26c0d063983ef6674&communityCode=BBMUNDO&requestor=signupRequestor&sourceInfoType=event&sourceInfoOrigin=66bd05fd5989143985138fc6">
                    <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center mt-2 mt-lg-5 mb-0">
                      Pareja: <span>$1950</span>
                    </p>
                  </a>
                  <a className="linkPrice" rel="noreferrer" target="_blank" href="https://nas.io/checkout-global?communityId=668edff26c0d063983ef6674&communityCode=BBMUNDO&requestor=signupRequestor&sourceInfoType=event&sourceInfoOrigin=66bd0540598914398513538e">
                    <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center mt-0">
                      Individual: <span>$1050</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-6 col-lg-3 ticketImg  disableTicket h-100 text-center">
              <img
                className="ticketImg position-relative"
                loading="lazy"
                src="https://masterbbmundo.s3.amazonaws.com/Boletos/BackBoleto_2.webp"
                alt="logoBbmundo"
                width="500"
                height="auto"
              />
              <div className="sectionsTicket">
                <p className="text-center priceTickets mb-3 disableText title">FASE 1</p>
                <p className="text-center priceTickets disableText mb-2 mb-lg-5 text-light">16 ago - 15 sep</p>
                <img
                  className="ticket position-relative disableImg"
                  loading="lazy"
                  src="https://masterbbmundo.s3.amazonaws.com/Boletos/Boleto_2.webp"
                  alt="ticket"
                  width="500"
                  height="auto"
                />
                <div className="priceTicket">
                  <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center mt-2 mt-lg-5 mb-0 disableText">
                    Pareja: <span>$1950</span>
                  </p>
                  <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center disableText">
                    Individual: <span>$1050</span>
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Fase 2 */}
          {startFase2 <= today && today <= endFase2 ? (
            <div className="col-6 col-lg-3 ticketImg  h-100 text-center">
              <img
                className="ticketImg position-relative"
                loading="lazy"
                src="https://masterbbmundo.s3.amazonaws.com/Boletos/BackBoleto_3.webp"
                alt="logoBbmundo"
                width="500"
                height="auto"
              />
              <div className="sectionsTicket">
                <p className="text-center priceTickets mb-3 title">FASE 2</p>
                <p className="text-center priceTickets text-light mb-2 mb-lg-5">16 sep - 04 oct</p>
                <img
                  className="ticket position-relative"
                  loading="lazy"
                  src="https://masterbbmundo.s3.amazonaws.com/Boletos/Boleto_3.webp"
                  alt="ticket"
                  width="500"
                  height="auto"
                />
                <div className="priceTicket">
                  <a className="linkPrice" href="https://nas.io/es-mx/checkout-global?communityId=668edff26c0d063983ef6674&communityCode=BBMUNDO&requestor=signupRequestor&sourceInfoType=event&sourceInfoOrigin=66e0c8d9b12ea00e41cc8f29">
                    <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center mt-2 mt-lg-5 mb-0">
                      Pareja: <span>$2160</span>
                    </p>
                  </a>
                  <a className="linkPrice" href="https://nas.io/checkout-global?communityId=668edff26c0d063983ef6674&communityCode=BBMUNDO&requestor=signupRequestor&sourceInfoType=event&sourceInfoOrigin=66e0c82d7326cd0a04f011f5">
                    <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center">
                      Individual: <span>$1150</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-6 col-lg-3 ticketImg  disableTicket h-100 text-center">
              <img
                className="ticketImg position-relative"
                loading="lazy"
                src="https://masterbbmundo.s3.amazonaws.com/Boletos/BackBoleto_3.webp"
                alt="logoBbmundo"
                width="500"
                height="auto"
              />
              <div className="sectionsTicket">
                <p className="text-center priceTickets mb-3 disableText title">FASE 2</p>
                <p className="text-center priceTickets disableText mb-2 mb-lg-5 text-light">16 sep - 04 oct</p>
                <img
                  className="ticket position-relative disableImg"
                  loading="lazy"
                  src="https://masterbbmundo.s3.amazonaws.com/Boletos/Boleto_3.webp"
                  alt="ticket"
                  width="500"
                  height="auto"
                />
                <div className="priceTicket">
                  <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center mt-2 mt-lg-5 mb-0 disableText">
                    Pareja: <span>$2160</span>
                  </p>
                  <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center disableText">
                    Individual: <span>$1150</span>
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Taquilla */}
          {startTaquilla <= today && today <= endFaseTaquilla ? (
            <div className="col-6 col-lg-3 ticketImg  h-100 text-center">
              <img
                className="ticketImg position-relative"
                loading="lazy"
                src="https://masterbbmundo.s3.amazonaws.com/Boletos/BackBoleto_4.webp"
                alt="logoBbmundo"
                width="500"
                height="auto"
              />
              <div className="sectionsTicket">
                <p className="text-center priceTickets mb-3 title">TAQUILLA</p>
                <p className="text-center priceTickets text-light mb-2 mb-lg-5">05 oct</p>
                <img
                  className="ticket position-relative"
                  loading="lazy"
                  src="https://masterbbmundo.s3.amazonaws.com/Boletos/Boleto_4.webp"
                  alt="ticket"
                  width="500"
                  height="auto"
                />
                <div className="priceTicket">
                  <a className="linkPrice" href="#">
                    <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center mt-2 mt-lg-5 mb-0">
                      Pareja: <span>$2500</span>
                    </p>
                  </a>
                  <a className="linkPrice" href="#">
                    <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center">
                      Individual: <span>$1400</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-6 col-lg-3 ticketImg  disableTicket h-100 text-center">
              <img
                className="ticketImg position-relative"
                loading="lazy"
                src="https://masterbbmundo.s3.amazonaws.com/Boletos/BackBoleto_4.webp"
                alt="logoBbmundo"
                width="500"
                height="auto"
              />
              <div className="sectionsTicket">
                <p className="text-center priceTickets mb-3 disableText title">TAQUILLA</p>
                <p className="text-center priceTickets disableText mb-2 mb-lg-5 text-light">05 oct</p>
                <img
                  className="ticket position-relative disableImg"
                  loading="lazy"
                  src="https://masterbbmundo.s3.amazonaws.com/Boletos/Boleto_4.webp"
                  alt="ticket"
                  width="500"
                  height="auto"
                />
                <div className="priceTicket">
                  <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center mt-2 mt-lg-5 mb-0 disableText">
                    Pareja: <span>$2500</span>
                  </p>
                  <p className="priceQuantityText priceQuantityTextBig ticketPriceText text-center disableText">
                    Individual: <span>$1400</span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

    </section>
  );
};

export default Boletos;
