
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const Time = () => {

    return (
        <>
            <div className="container-fluid countdownTimer">
                <div className="row w-100 m-0">
                    <div className="col-12 px-2 d-flex justify-content-center position-relative">
                        <img className="adorno1" src="https://masterbbmundo.s3.amazonaws.com/Contador/Casita.webp" alt="Casita" width="500px" height="auto" />
                        <img className="adorno2" src="https://masterbbmundo.s3.amazonaws.com/Contador/Carrito.webp" alt="Tren" width="400px" height="auto" />
                        <div className="text-center containerCountdown px-3">
                            <FlipClockCountdown  to={new Date('2024-10-05T00:00:00')} labels={['días', 'horas', 'minutos', 'segundos']} className='flip-clock' renderMap={[true, true, true, false]}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Time;