const Inicio = () => {
    return (
        <>
            <div id="home" class="home container-fluid p-0">
                <div class="containerHomeText">
                    <img class="py-4 logoHome" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Home/Logo_42819146-50ca-4161-82f8-0f7ef646eb82_1.png" alt="logoBbmundo" width="850" height="auto" />
                    <h1 class="dateHome pt-1 pb-0 text-uppercase">5 - Octubre - 2024</h1>
                    <img class="nube1" src="https://masterbbmundo.s3.amazonaws.com/Home/Nuve_Pequena.webp" alt="nube" width="150px" height="auto" />
                    <img class="nube2" src="https://masterbbmundo.s3.amazonaws.com/Home/Nube_Grande_f80c1211-be1d-4f63-96c2-fddace6fa8ee.png" alt="nube" width="250px" height="auto" />
                    <img class="avioneta" src="https://masterbbmundo.s3.amazonaws.com/Home/Avioneta.webp" alt="avioneta" width="450px" height="auto" />
                </div>
                
            </div>
        </>
    )
}
export default Inicio;